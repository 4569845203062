/* stylelint-disable selector-pseudo-class-no-unknown  */
@import "./variables.scss";
@import "./mixins.scss";
@import "../fonts/OpenSans/open-sans.css";
@import "./antd.min.css";


#__next {
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  color: $black;
  font-size: 16px;
  font-weight: normal;
  z-index: 1;

  @media print {
    overflow: visible;
  }
  & > main {
    flex: 1;
    position: relative;
  }

  & ~ div {
    z-index: 1001;
    position: relative;
  }
}
* {
  margin: 0;
  padding: 0;
}
#chat-widget-container {
  /* rewrite chat widget inline styles */
  margin-bottom: -20px;
  right: 0 !important;

  @media print {
    display: none !important;
  }
}

body {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    overflow: hidden;
    position: fixed;
  }
  &.fixedMobile {
    @media screen and (max-width: $breakpoint-lg) {
      overflow: hidden;
      position: fixed;
    }
  }

  .vjs-poster {
    background-size: cover;
    background-position: 46% 50%;
  }
}

blockquote {
  width: 50%;
  padding-left: 60px;
  border-left: 5px solid $teal;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  color: $black;

  @media screen and (max-width: $breakpoint-lg) {
    padding-left: 50px;
    border-left-width: 4px;
    font-size: 13px;
    line-height: 24px;
  }

  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
    padding-left: 40px;
    border-left-width: 3px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding-left: 30px;
    border-left-width: 2px;
    font-size: 12px;
    line-height: 20px;
  }
  & + p {
    margin: 0;
    font-weight: bold;
    color: $black;
    &,
    & + p {
      padding-left: 65px;
      font-size: 13px;
      line-height: 20px;

      @media screen and (max-width: $breakpoint-lg) {
        padding-left: 54px;
        font-size: 12px;
        line-height: 17px;
      }

      @media screen and (max-width: $breakpoint-md) {
        padding-left: 43px;
      }

      @media screen and (max-width: $breakpoint-sm) {
        padding-left: 32px;
      }
    }
    & + p {
      margin-bottom: 1em;
      color: $black;
    }
  }
}

.schedule {
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  background-position: center;
  background-image: url("../img/schedule.svg");
  margin-right: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $black;
}

.ant-modal-root {
  position: relative;
  z-index: 1001;
}

.ant-image-preview-mask,
.ant-modal-mask {
  background-color: rgba($black, 0.65);
}
.ant-modal-body {
  padding: 48px 42px;

  @media screen and (max-width: $breakpoint-lg) {
    padding: 24px 15px;
  }
}
.close-icon-wrapper {
  color: $black;
  & > *:last-child {
    display: none;
  }

  @media screen and (max-width: $breakpoint-lg) {
    & > *:first-child {
      display: none;
    }
    & > *:last-child {
      display: inline-block;
      margin-left: -10px;
      font-size: 13px;
    }
  }
}
.ant-image-preview-wrap,
.ant-modal-wrap {
  height: 100%;
  overflow: hidden;
}
.ant-modal {
  @media screen and (max-height: 500px) {
    top: 0;
  }
}

.mapMultiple {
  height: 100%;
  & > div {
    background: linear-gradient(0deg, $pink-gray 50%, $sea-blue 50%);
  }
}
.mapInfoWrapper * {
  text-align: center;
  width: 180px;
  max-width: 100%;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
}
.withLink {
  &:hover {
    cursor: pointer;
    .link {
      text-decoration: underline;
    }
  }
  .link {
    margin-top: 5px;
  }
}
.gm-style-iw-a .gm-style-iw-t .gm-style-iw {
  padding-left: 6px;
  padding-top: 6px;
}

.ant-picker-dropdown {
  pointer-events: all !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: $teal;
  }
  .ant-picker-header-view {
    button:hover {
      color: $teal;
    }
  }
  .ant-picker-header {
    border-bottom: none;
  }
  .ant-picker-cell-in-view {
    color: $black;
    &.ant-picker-cell-today .ant-picker-cell-inner::before {
      border-color: $teal;
      border-radius: 0;
    }
  }
  .ant-picker-header-view button {
    color: $black;
  }
  .ant-picker-header button {
    color: $black;
  }
  .ant-picker-cell-disabled::before {
    background: transparent;
  }
  .ant-picker-cell .ant-picker-cell-inner {
    font-size: 12px;
    line-height: 32px;
    min-width: 32px;
    height: 32px;
    border-radius: 0;
  }
  .ant-picker-date-panel .ant-picker-content th {
    padding-bottom: 8px;
    color: $black;
  }
  .ant-picker-clear {
    padding: 3px;
    background-color: $white;
    margin-right: -5px;
  }
  .ant-picker {
    border-radius: 0;
    width: 100%;
    border-color: transparent;
    &:hover {
      border-color: transparent;
    }
  }
  .ant-picker-next-icon,
  .ant-picker-prev-icon,
  .ant-picker-super-prev-icon,
  .ant-picker-super-next-icon {
    color: $black;
    font-weight: bold;
    &::before,
    &::after {
      border-width: 2.5px 0 0 2.5px;
    }
  }
  .ant-picker-focused {
    border-color: transparent;
    box-shadow: none;
    outline: 1px solid transparent;
  }
  .ant-picker-input > input {
    font-size: 13px;
    height: 22px;
    cursor: pointer;
    color: $black;

    @media screen and (max-width: $breakpoint-lg) {
      font-size: 14px;
    }
    &::placeholder {
      font-weight: normal;
      color: $black;
    }
  }
  .ant-picker.ant-picker-disabled {
    background-color: transparent;
    pointer-events: none;
    .ant-picker-input > input {
      &,
      &::placeholder {
        color: $disabled-value;
        -webkit-text-fill-color: $disabled-value;

        @media screen and (max-width: $breakpoint-lg) {
          color: $black;
          -webkit-text-fill-color: $black;
        }
      }
      & ~ span > svg {
        color: $disabled-value;
      }
    }
  }
  .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
  .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: transparent;
    outline: 1px solid $teal;
  }

  .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
    color: $teal;
    font-size: 16px;
  }
  .ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 20px 40px 20px 20px;
  }
